import { useState, useEffect } from "react";

import { formatUsdollars } from '../../utils/numberFormatter';

import api from '../../api/user';

const Invoice = ({match}) => {

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [billPeriod, setBillPeriod] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [paymethod, setPayMethod] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [taxId, setTaxId] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [cp,setCp] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const [amount, setAmount] = useState('0');
  const [currency, setCurrency] = useState('');

  const [items, setItems] = useState([]);
  

    useEffect( ()=> {

        const loadData = async () => {
            try {
                
                const tokenString = `Bearer ${match.params.token}`;
                api.defaults.headers.common['Authorization'] = tokenString;
        
                const trx = await api.get(`/users/transactions/${match.params.subscriptionID}?transactionID=${match.params.transactionID}`);
                const order = await api.get(`/users/orders?subscriptionID=${match.params.subscriptionID}`)
                
                
                const {
                  company,
                  contact,
                  product,
                  summary
                } = order.data[0].cart

                setInvoiceNumber(trx.data.id);
                setIssueDate(trx.data.time);
                setPayMethod(`Paypal/Credit Card`)

                const endDate = new Date(trx.data.time);
                const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
                setBillPeriod( ` ${months[endDate.getMonth()]} - ${endDate.getFullYear()}`)
                
                setCompanyName( company.name);
                setTaxId( company.rfc);
                setAddress1( company.address1);
                setAddress2( company.address2);
                setCity( company.city);
                setProvince( company.province);
                setCountry( company.country);
                setContactEmail( contact.email);
                setCp(company.cp);
                setItems( product.subproducts )

                const amt = formatUsdollars(summary.total);
                setAmount(amt);
                setCurrency(trx.data.amount_with_breakdown.gross_amount.currency_code)

                
            }
            catch(e){
                console.log(e);
            }
    
        }
        loadData();

    },[match.params.subscriptionID,match.params.transactionID,match.params.token]);

  return (
    <section>
      <div className="shopcart card">
        <div className="invoice-header">
          <div className="invoice-header-col">
            <div className="anet-info">
              <p>
                <i className="fab fa-react fa-3x"></i>
              </p>
              
              <h1 className="anet-name">ANET Cloud</h1>
              <p className="anet-footnote">
                Great America Parkway, Santa Clara, CA 95054
              </p>
              <p className="anet-footnote">Phone: +1-(816)-934-6014</p>
              <p className="anet-footnote">Fax: +1-(408)-538-8920</p>
              <p className="anet-footnote">
                Contact: contact@aristanetworks.cloud
              </p>
            </div>
            <div className="table1">
              <div className="table1row">
                <p className="title">Invoice Id</p>
                <p className="titlecap">{invoiceNumber}</p>
              </div>
              <div className="table1row">
                <p className="title">Billing period</p>
                <p className="titlecap">{billPeriod} </p>
              </div>
              <div className="table1row">
                <p className="title">Issued Date</p>
                <p className="titlecap">{issueDate}</p>
              </div>
              <div className="table1row">
                <p className="title">Payment method</p>
                <p className="titlecap">{paymethod}</p>
              </div>
            </div>
          </div>

          <div className="invoice-header-col">
            <div className="table1">
              <div className="table1row">
                <p className="title">Company Name</p>
                <p className="titlecap">
                  {companyName}
                </p>
              </div>
              <div className="table1row">
                <p className="title">Tax Id Number</p>
                <p className="titlecap">{taxId}</p>
              </div>
              <div className="table1row">
                <p className="title">Address 1</p>
                <p className="titlecap">{address1}</p>
              </div>
              <div className="table1row">
                <p className="title">Address 2</p>
                <p className="titlecap">{address2}, CP {cp}</p>
              </div>
              <div className="table1row">
                <p className="title">City</p>
                <p className="titlecap">{city}</p>
              </div>
              <div className="table1row">
                <p className="title">Province / Country</p>
                <p className="titlecap">{province}, {country}</p>
              </div>
              <div className="table1row">
                <p className="title">Contact Email</p>
                <p className="titlecap">{contactEmail}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="invoice-body">
          <div className="bill-table-header">
              <p>Type</p>
              <p>Description</p>
              <p>Amount</p>
          </div>
          <div className="bill-table-content">

              {
                items.map( (i,n) => 
                <div className="bill-table-row invoice-detail" key={n}>
                  <p>{i.short_name}</p>
                  <p>{i.description}</p>
                  <p>{i.price}</p>                  
              </div> )
              }

          </div>

          <div className="bill-table-row bill-summary">
              <p className="subtotal-label">Total:</p>
              <p>{amount} {currency}</p>
          </div>
          <div>
          </div>
        </div>
        <div className="invoice-footer">
          <p className="anet-footnote">
          ©{ (new Date()).getFullYear()} Arista Networks, Inc. 20-1751121 (I.R.S. Employer Identification Number)
          </p>
        </div>
      </div>
    </section>
  );
};

export { Invoice as default };
