import React, { useState, useEffect } from 'react';

const Stats = () => {

    const [countA, setCountA] = useState(0);
    const [countB, setCountB] = useState(0);
    const [countC, setCountC] = useState(0);

    useEffect(() => {

        setInterval(() => {
            setCountA( prev => prev > 197 ? prev : prev + 1);
        }, 30);

        setInterval(() => {
            setCountB( prev => prev > 347 ? prev : prev + 1);
        }, 25);

        setInterval(() => {
            setCountC( prev => prev > 524 ? prev : prev + 1);
        }, 10);

    }, [])

    return (
        <section className='stats'>
            <div className='container'>

                <h3 className='stats-heading text-center my-1'>
                    Tons of applications are being currently hosted in our infraestructure. We can help you deployed your dream projects in matter of hours!
                </h3>

                <div className='cuadrilla cuadrilla-3 text-center my-4'>
                    <div>
                        <i className='fas fa-server fa-3x'></i>
                        <h3>{countA}</h3>
                        <p className='text-sencondary'>Web & Mobile applications</p>
                    </div>

                    <div>
                        <i className='fas fa-upload fa-3x'></i>
                        <h3>{countB}TB</h3>
                        <p className='text-sencondary'>Solid State Storage</p>

                    </div>

                    <div>
                        <i className='fas fa-project-diagram fa-3x'></i>
                        <h3>{countC} git</h3>
                        <p className='text-sencondary'>Repositories</p>
                    </div>

                </div>

            </div>
        </section>
    );
}

export { Stats as default };