import { useState, useContext } from "react";

import Modal from "./modal";
import Loader from "../loader";

import api from "../../api/user";
import { history } from "../../router/AppRouter";
import { LoginContext } from "../../store/store";

const Checkout = () => {
  const { order, dispatchOrder, loginInfo, cartList } =
    useContext(LoginContext);

  const [name, setName] = useState(loginInfo.name);
  const [lastname, setLastname] = useState(loginInfo.lastname);
  const [email, setEmail] = useState(loginInfo.email);
  const [phone, setPhone] = useState("");
  const [company_name, setCompany] = useState("");
  const [rfc, setRfc] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [cp, setCp] = useState("");
  const [province, setProvice] = useState("");
  const [country, setCountry] = useState("");
  const [suscriptionPlanId, setSuscriptionPlanId] = useState("");

  // const onCheckout = async (e) => {
  //   e.preventDefault();

  //   dispatchOrder({
  //     type: "SET_ORDER",
  //     ...order,
  //     subproducts: cartList,
  //   });

  //   const modal = document.getElementById("modalWindow");
  //   modal.style.display = "block";

  //   const tokenString = `Bearer ${loginInfo.currToken}`;
  //   api.defaults.headers.common["Authorization"] = tokenString;

  //   // Actualizar el carrito
  //   await api.patch("/users/me", {
  //     cart: {
  //       product: {
  //         short_name: order.short_name,
  //         description: order.description,
  //         product_type: order.product_type,
  //         quantity: order.quantity,
  //         price: order.price,
  //         subproducts: cartList,
  //         paypal_product_id: order.paypal_product_id,
  //       },
  //       company: {
  //         name: company_name,
  //         rfc: rfc,
  //         address1: address1,
  //         address2: address2,
  //         city,
  //         cp,
  //         province,
  //         country,
  //       },
  //       contact: {
  //         name,
  //         lastname,
  //         email,
  //         phone,
  //       },
  //       summary: {
  //         items: cartList.length,
  //         subtotal: order.subtotal,
  //         tax: order.tax,
  //         total: order.total,
  //       },
  //     },
  //   });

  //   const plan = await api.post("/users/plans");
  //   modal.style.display = "none";
  //   history.push(`/payment/${tokenString}/${plan.data.id}`);
  // };

  async function generateLink(e){
      e.preventDefault();
      /// HERE NEED TO CALL api.get('users/paymentlink')
      // This will send a link to the user's email
      // Need to use a sendgrid email template
      // We need to reuse payment/:token/:planid
      // 
      dispatchOrder({
        type: "SET_ORDER",
        ...order,
        subproducts: cartList,
      });
  
      const modal = document.getElementById("modalWindow");
      modal.style.display = "block";
  
      try {
        const tokenString = `Bearer ${loginInfo.currToken}`;
        api.defaults.headers.common["Authorization"] = tokenString;
    
        // Actualizar el carrito
        await api.patch("/users/me", {
          cart: {
            product: {
              short_name: order.short_name,
              description: order.description,
              product_type: order.product_type,
              quantity: order.quantity,
              price: order.price,
              subproducts: cartList,
              paypal_product_id: order.paypal_product_id,
            },
            company: {
              name: company_name,
              rfc: rfc,
              address1: address1,
              address2: address2,
              city,
              cp,
              province,
              country,
            },
            contact: {
              name,
              lastname,
              email,
              phone,
            },
            summary: {
              items: cartList.length,
              subtotal: order.subtotal,
              tax: order.tax,
              total: order.total,
            },
          },
        });
        /// Creates the new paypal plan
        // const res = await api.post("/users/waits");
        const plan = await api.post("/users/plans");
        setSuscriptionPlanId(plan.data.id)
        await api.post('/users/paylink', { 
          suscription_id: plan.data.id 
        })
        modal.style.display = "none";
        
        const diag = document.getElementById("alert-new-modal");
        diag.showModal();
    
        
      }
      catch(e){
        modal.style.display = "none";
        alert('Something when wrong!')
      }

  
  }

  function onCloseDialog(){
    const diag = document.getElementById("alert-new-modal");
    diag.close();
    history.push('/dashboard')
  }

  return (
    <section id="checkout">
      <Modal closebutton={false}>
        <Loader />
      </Modal>
    
      <dialog class='new-modal' id='alert-new-modal'>
          <h2>Thanks for checking out, you plan code is: {suscriptionPlanId}</h2>
          <p>Please check you email address <b>{email}</b></p>
          <p>An email has been sent to you in order for you to enter your payment options using paypal secure transaction dialog.</p>
          <p><b>IMPORTANT!</b>For security reasons the email and payment link sent to you will expire within the next few hours. If you do not manage to concrete your payment your account is going to be disabled and all services will be downgraded.</p>

          <button className="btn btn-primary" onClick={onCloseDialog}>Close</button>
      </dialog>

      <form className="checkout card" onSubmit={generateLink}>
        <h3 className="text-center">Checkout Information</h3>
        <div className="checkout-form">
          <input
            type="text"
            placeholder="Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Last Name"
            value={lastname}
            required
            onChange={(e) => setLastname(e.target.value)}
          ></input>
          <input
            type="email"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Phone number"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
          ></input>
        </div>
        <h3 className="text-center">Invoice to</h3>
        <div className="checkout-form">
          <input
            type="text"
            placeholder="Company Name"
            value={company_name}
            required
            onChange={(e) => setCompany(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="RFC"
            value={rfc}
            required
            onChange={(e) => setRfc(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Addres Line 1"
            value={address1}
            required
            onChange={(e) => setAddress1(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Addres Line 2"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="City"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Post code"
            value={cp}
            required
            onChange={(e) => setCp(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Estate or Provice"
            value={province}
            required
            onChange={(e) => setProvice(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Country"
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          ></input>
        </div>

        <input
          type="submit"
          value="Checkout"
          className="btn btn-secondary my-1"
          href="#checkout"
        ></input>
      </form>
    </section>
  );
};

export { Checkout as default };
