import { useState, useEffect } from "react";
import { PayPalButton } from "react-paypal-button-v2";

import api from "../../api/user";
import { history } from "../../router/AppRouter";


import Modal from "./modal";

const Payment = ({ match }) => {


  // const [render, setRender] = useState(false);

  const [planId, setPlanId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [subscriptionData, setSubscriptionData] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [total, setTotal] = useState('');
  const [expires, setExpires] = useState('');

    useEffect( () => {

    const loadData = async () =>{
      
      const tokenString = `Bearer ${match.params.token}`;
      api.defaults.headers.common["Authorization"] = tokenString;
  
      const res = await api.get(`/users/orders?planid=${match.params.planid}`);
      const info = res.data[0];

      const expiresAt = new Date(info.plan.create_time);
      expiresAt.setFullYear( expiresAt.getFullYear() + 1 );

      setOrderId(info._id);
      setPlanId(info.plan.id);
      setName(info.plan.name);
      setDescription(info.plan.description);
      setExpires(expiresAt.toString());
      setTotal(info.cart.summary.total);
    }

   loadData();

  }, [match.params.planid]);


  const onCrearSubscripcion = (data, actions) => {
      return actions.subscription.create({
          plan_id: match.params.planid
      });
  }

  const onAprobarPago = (data, actions) => {
    // Capture the funds from the transaction
    return actions.subscription.get().then(function(details) {
        // Show a success message to your buyer
        const modal = document.getElementById("modalWindow");
        modal.style.display = "block";
        setSubscriptionData(data);                        
    });
}

  const onFinish = async () => {
    
    const tokenString = `Bearer ${match.params.token}`;
    api.defaults.headers.common["Authorization"] = tokenString;
    
    await api.patch(`/users/orders/${orderId}`,{
      subscription: subscriptionData
    });

    history.push("/dashboard");

  }

  return (
    <section id="payment">
      <Modal closebutton={false}>
        <div className="text-center my-1 py-2">
          <p>Thansk you!, your subscription has been place correctly. You will recieve and email with invoice instruccion and details. You can now go to dashboard! We will be preparing instructions for you to enable your services briefly. Congratulation! and welcome to ANET Cloud</p>
          <button className="btn btn-dark" onClick={onFinish}>Finish</button>
        </div>
      </Modal>
      <div className="shopcart card">
      <h3 className="text-center">Suscription Payment Plan</h3>
        <div className="chartcontent">
          <div className="rowitm">
            <p>Plan id</p>
            <p>{planId}</p>
          </div>

          <div className="rowitm">
            <p>Product name</p>
            <p>{name}</p>
          </div>
          <div className="rowitm">
            <p>Description</p>
            <p>{description}</p>
          </div>

          <div className="rowitm">
            <p>Expires at</p>
            <p>{expires}</p>
          </div>

          <div className="rowitm">
            <p>Total</p>
            <p>
              {total}
            </p>
          </div>

        </div>

        <PayPalButton
                options={{vault: true}}
                createSubscription={onCrearSubscripcion}
                onApprove={onAprobarPago}
        />
        
        <button 
        className="btn btn-light my-1"
        onClick={ (e)=>{
            e.preventDefault();
            history.push('/purchase');
        } }
        >
          Go back
        </button>
        
      </div>
    </section>
  );
};

export { Payment as default };
