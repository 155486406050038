import { Switch, Router } from 'react-router-dom';

import { createBrowserHistory} from 'history';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import Home from '../components/home/Home';
import Features from '../components/features/Features';
import Docs from '../components/docs/Docs';
import Signup from '../components/signup/signup';
import purchase from '../components/purchase/purchase';
import dashboard from '../components/dashboard/dashboard';
import login from '../components/login/login';


import Store from '../store/store';
import mysuscriptions from '../components/dashboard/mysuscriptions';
import billing from '../components/dashboard/billing';
import invoice from '../components/dashboard/invoice';
import payment from '../components/purchase/payment';
import Sla from '../components/docs/Sla';
import Hosting from '../components/docs/Hosting';

export const history =  createBrowserHistory(); 

const AppRouter = () => (

    <Router history={history}>
        <div>
            <Store>
                <Switch>
                    <PublicRoute path='/' component={Home} exact={true} />
                    <PublicRoute path='/features' component={Features} exact={true} />
                    <PublicRoute path='/docs' component={Docs} exact={true} />
                    <PublicRoute path='/sla' component={Sla} exact={true} />
                    <PublicRoute path='/hosting' component={Hosting} exact={true} />
                    <PublicRoute path='/signup' component={Signup} exact={true} />
                    <PublicRoute path='/login' component={login} exact={true} />

                    <PrivateRoute path='/purchase' component={purchase} />
                    <PrivateRoute path='/dashboard' component={dashboard} />
                    <PrivateRoute path='/mysuscriptions' component={mysuscriptions} />
                    <PrivateRoute path='/billing' component={billing} />
                    <PublicRoute path='/payment/:token/:planid' component={payment} />
                    <PublicRoute path='/invoice/:subscriptionID/:transactionID/:token' component={invoice} />

                </Switch>
            </Store>
        </div>
    </Router>

);

export default AppRouter;
